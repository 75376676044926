<template>
	<div 
		v-if="componentData?.content" 
		class="container wp-core-gb"
		:class="[componentData?.rootClass]"
	>
		<div class="row">
			<div class="col-12">
				<div v-html="componentData?.content" class="backend-output"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
	const props = defineProps({
		data: Object
	});

	// data
	const componentData = props?.data?.data || {};
</script>

<style lang="scss" scoped>
	.backend-output {
		:deep(h1), :deep(.h1) {
      font-size: 2.813rem!important;
    }

    :deep(h2), :deep(.h2) {
      font-size: 2.188rem!important;
    }

    :deep(h3), :deep(.h3) {
      font-size: 1.875rem!important;
    }

    :deep(h4), :deep(.h4) {
      font-size: 1.563rem!important;
    }

    :deep(h5), :deep(.h5) {
      font-size: 1.25rem!important;
    }

    :ddep(h6), :deep(.h6), :deep(p) {
      font-size: 1rem!important;
    }

		:deep(table) {
			tr, td {
				vertical-align: top;
				font-size: 0.938rem!important;
				color: var(--primary)!important;
			}

			> tbody > tr:nth-of-type(odd) {
				background: rgba(0, 0, 0, 0.05);
			}
		}

		:deep(.wp-block-table) {
			overflow-x: auto;
    	-webkit-overflow-scrolling: touch;

			table {
				width: 100%;
			}
		}

		:deep(.has-text-align-center) {
			text-align: center;
		}

		@include mediaq('>lg') {
			:deep(table) {
				tr, td {
					font-size: 1rem!important;
				}
			}
		}
	}
</style>